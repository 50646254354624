import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import config from '../../config';
import convertJSON from '../../utilities/convertJSON';
import authHeaders from '../../utilities/authHeaders';
import { useStore } from '../../StoreProvider';
import Btn from '../../components/Btn';
import { ACTIONS } from '../../Actions';

const ReportAutomation = () => {
    const { state, dispatch } = useStore();
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);
    const [reportType, setReportType] = useState('');
    const [quotedProductsReport, setQuotedProductsReport] = useState(false);
    const [quickBookPOsReport, setQuickBookPOsReport] = useState(false);
    const [frequency, setFrequency] = useState('');
    const [weeklyDay, setWeeklyDay] = useState('');
    const [timeOfDay, setTimeOfDay] = useState('');
    const [meridiem, setMeridiem] = useState('');
    const [userDropdown, setUserDropdown] = useState([]);
    const [recipients, setRecipients] = useState([]);
    const [timeframes, setTimeframes] = useState([]);
    const [selectedTimeframe, setSelectedTimeframe] = useState('');
    const [years, setYears] = useState([]);
    const [addReport, setAddReport] = useState(false);
    const [reportsListed, setReportsListed] = useState([]);

    useEffect(() => {
        async function fetchData() {
            await getReports();
            await connectDBUsers();
        }

        fetchData();
    }, []);

    useEffect(() => {
        const currentYear = new Date().getFullYear();
        const startYear = 2020;
        const yearsArray = [];
        for (let year = startYear; year <= currentYear; year++) {
          yearsArray.push(year);
        }
        setYears(yearsArray);
      }, []);

    useEffect(() => {
        async function fetchData() {
            if (reportType === 'quoted_products') {
                setQuotedProductsReport(true);

                try {
                    let timeframes = [];

                    const res = await fetch(`${config.base_api}/configuration/timeframe/${reportType}`, { headers });
                    const data = await convertJSON(res);

                    for (let i = 0; i < data.length; i++) {
                        timeframes.push(data[i].name)
                    }

                    setTimeframes(timeframes)
                } catch (error) {
                    console.log(error)
                }
            } else {
                setQuotedProductsReport(false);
            }
            if (reportType === 'quickbook_pos') {
                setQuickBookPOsReport(true);
            } else {
                setQuickBookPOsReport(false);
            }
        }
        fetchData();
    }, [reportType]);

    const getReports = async () => {
        try {
            const res = await fetch(`${config.base_api}/configuration/reports`, { headers });
            const data = await convertJSON(res);

            await displayReports(data)

        } catch (error) {
            console.log(error)
        }
    }

    const displayReports = async (data) => {
        let savedReports = [];

        for (let i = 0; i < data.length; i++) {
            let reportType = '';

            if (data[i].name === 'quoted_products') {
                reportType = 'Quoted Products Report' + ' (' + data[i].selectedTimeframe + ')'
            } else if (data[i].name === 'lad_projects') {
                reportType = 'Customers Contacted Past 7 Days'
            } else if (data[i].name === 'quickbook_pos') {
                reportType = 'QuickBook Purchase Orders' + ' (' + data[i].selectedTimeframe + ')'
            } else if (data[i].name === 'quickbook_customers') {
                reportType = 'QuickBook Customers'
            } else if (data[i].name === 'customers_with_tax_exemption') {
                reportType = 'Customers with Tax Exemption'
            } else if (data[i].name === 'all_open_projects') {
                reportType = 'All Open Projects'
            } else if (data[i].name === 'all_closed_projects') {
                reportType = 'All Closed Projects'
            } else if (data[i].name === 'all_projects') {
                reportType = 'All Projects'
            } else if (data[i].name === 'accounting_ltd_po') {
                reportType = 'Accounting LTD PO'
            }

            let frequency = data[i].frequency.charAt(0).toUpperCase() + data[i].frequency.slice(1);

            if (frequency === 'Weekly') {
                frequency = frequency + ' (' + data[i].weeklyDay.charAt(0).toUpperCase() + data[i].weeklyDay.slice(1) + ')'
            }

            let recipients = JSON.parse(data[i].recipients);

            let report = {
                id: data[i].id,
                reportType,
                selectedTimeframe: data[i].selectedTimeframe,
                frequency,
                weeklyDay: data[i].weeklyDay,
                timeOfDay: data[i].timeOfDay + ' ' + data[i].meridiem,
                recipients: recipients.map(recipient => recipient.label).join(', ')
            }

            savedReports.push(report)
        }
        setReportsListed(savedReports)
    }

    const handleFrequencyChange = (e) => {
        setFrequency(e.target.value);
    };

    const connectDBUsers = async () => {
        let dropdownArray = [{ userName: 'Marc Parker', userEmail: 'marc@playgroundboss.com' }]

        try {
            const res = await fetch(`${config.base_api}/users/db`, { headers });
            const data = await convertJSON(res);

            let awsUsers = data?.filter((user) => user?.aws_id !== null)

            for (let i = 0; i < awsUsers.length; i++) {
                let userName = awsUsers[i]?.name || awsUsers[i]?.email.toLowerCase();
                let userEmail = awsUsers[i]?.email.toLowerCase();
                let isOperations = awsUsers[i]?.operations;

                if (isOperations == true) {
                    dropdownArray.push({ userName, userEmail })
                }
            }
        } catch (error) {
            console.log(error)
        }

        setUserDropdown(dropdownArray)
        setRecipients([{ value: 'marc@playgroundboss.com', label: 'Marc Parker' }])
    }

    const handleRecipientsChange = (selectedOptions) => {
        setRecipients(selectedOptions || []);
    };

    const onSave = async () => {
        if (reportType === '' || frequency === '' || timeOfDay === '' || meridiem === '' || recipients.length === 0) {
            dispatch({ type: ACTIONS.TOAST, payload: { type: 'error', message: 'Please fill out all required fields', isError: true } });
            return;
        } else if (frequency === 'weekly' && weeklyDay === '') {
            dispatch({ type: ACTIONS.TOAST, payload: { type: 'error', message: 'Please fill out all required fields', isError: true } });
            return;
        } else if (reportType === 'quoted_products' && selectedTimeframe === '') {
            dispatch({ type: ACTIONS.TOAST, payload: { type: 'error', message: 'Please fill out all required fields', isError: true } });
            return;
        } else if (reportType === 'quickbook_pos' && selectedTimeframe === '') {
            dispatch({ type: ACTIONS.TOAST, playload: { type: 'error', message: 'Please fill out all required fields', isError: true } });
        }

        const newReport = {
            type: 'report',
            name: reportType,
            frequency,
            timeOfDay,
            meridiem,
            recipients,
            weeklyDay,
            selectedTimeframe
        }

        try {
            const res = await fetch(`${config.base_api}/configuration/report/create`,
                {
                    method: 'POST',
                    headers,
                    body: JSON.stringify(newReport)
                });

            let data = await convertJSON(res);
            data = [data]

            if (res.ok) {
                dispatch({ type: ACTIONS.TOAST, payload: { type: 'success', message: 'Automated Report saved' } });
                await getReports();
            }
        } catch (error) {
            console.log(error)
            dispatch({ type: ACTIONS.TOAST, payload: { type: 'error', message: 'Error saving Automated Report', isError: true } });
        }

        setReportType('')
        setSelectedTimeframe('')
        setFrequency('')
        setWeeklyDay('')
        setTimeOfDay('')
        setMeridiem('')
        setRecipients([{ value: 'marc@playgroundboss.com', label: 'Marc Parker' }])
        setAddReport(false)
    };

    const deleteReport = async (id) => {
        try {
            const res = await fetch(`${config.base_api}/configuration/report/delete/${id}`,
             { 
                method: 'DELETE',
                headers,
                body: JSON.stringify({}) 
            });

            if (res.ok) {
                dispatch({ type: ACTIONS.TOAST, payload: { type: 'success', message: 'Automated Report deleted' } });
                let updatedReports = reportsListed.filter(report => report.id !== id)
                setReportsListed(updatedReports)
            }

        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className='flex flex-column project-box widget-width margin-t-xxlg'>
            <div className='flex justify-between pad-sm pad-b-sm pad-l-lg'>
                <p className='text-lrg text-bold margin-r-xxlg'>Automated Reports</p>
                {addReport ? null :
                    <Btn title='Add' icon='add' btnClass='text-bold bg-white text-green b2-green darken-background box-shadow pad-lr-xlg text-small' onClick={() => { setAddReport(true) }}
                    />
                }
            </div>
            {addReport ?
                <div className='pad-sm project-box border-radius-md margin-b-sm'>
                    <div className='width-100 flex'>
                        <div className='pad-xs flex-column width-25'>
                            <label className='pad-b-xs' id='report_type' htmlFor='report_type'>
                                Report Type:
                            </label>
                            <select className='pad-xs pointer' name="report_type" onChange={(e) => setReportType(e.target.value)}>
                                <option disabled="disabled" value={null} selected="selected">Select ...</option>
                                <option value="quoted_products">Quoted Products Report</option>
                                <option value="lad_projects">Customers Contacted Past 7 Days</option>
                                <option value="quickbook_pos">QuickBook Purchase Orders</option>
                                <option value="quickbook_customers">QuickBook Customers</option>
                                <option value="customers_with_tax_exemption">Customers with Tax Exemption</option>
                                <option value="all_open_projects">All Open Projects</option>
                                <option value="all_closed_projects">All Closed Projects</option>
                                <option value="all_projects">All Projects</option>
                                <option value="accounting_ltd_po">Accounting LTD PO</option>
                            </select>
                            {quotedProductsReport && (
                                <>
                                    <label className='pad-t-sm' id='quoted_products_params' htmlFor='quoted_products_params'>
                                        Timeframe:
                                    </label>
                                    <select className='pad-xs pointer' name='quoted_products_params' onChange={(e) => setSelectedTimeframe(e.target.value)}>
                                        <option disabled="disabled" value={null} selected="selected">Select ...</option>
                                        {timeframes.map(timeframe => (
                                            <option key={timeframe} value={timeframe}>{timeframe}</option>
                                        ))}
                                    </select>
                                </>
                            )}
                            {quickBookPOsReport && (
                                <>
                                    <label className='pad-t-sm' id='quickbook-pos-param' htmlFor='quickbook-pos-param'>
                                        Year:
                                    </label>
                                    <select className='pad-xs pointer' name='quickbook-pos-param' onChange={(e) => setSelectedTimeframe(e.target.value)}>
                                        <option disabled="disabled" value={null} selected="selected">Select ...</option>
                                        {years.map(yr => (
                                            <option key={yr} value={yr}>
                                                {yr}
                                            </option>
                                        ))}
                                    </select>
                                </>
                            )}
                        </div>
                        <div className='pad-xs flex-column width-25 align-center'>
                            <p className='pad-b-xs'>Frequency: </p>
                            <form className='flex column-gap-md'>
                                <div>
                                    <label htmlFor="daily" className='pointer'>Daily </label>
                                    <input type="radio" id="daily" name="frequency" value="daily" className='pointer' onChange={handleFrequencyChange}></input>
                                </div>
                                <div>
                                    <label htmlFor="weekly" className='pointer'>Weekly </label>
                                    <input type="radio" id="weekly" name="frequency" value="weekly" className='pointer' onChange={handleFrequencyChange}></input>
                                </div>
                            </form>
                            {frequency === 'weekly' && (
                                <div className='pad-t-sm'>
                                    <label htmlFor='weekly_day'>Select Day: </label>
                                    <select className='pad-xs pointer' name='weekly_day' onChange={(e) => setWeeklyDay(e.target.value)}>
                                        <option value="">Select...</option>
                                        <option value="monday">Monday</option>
                                        <option value="tuesday">Tuesday</option>
                                        <option value="wednesday">Wednesday</option>
                                        <option value="thursday">Thursday</option>
                                        <option value="friday">Friday</option>
                                        <option value="saturday">Saturday</option>
                                        <option value="sunday">Sunday</option>
                                    </select>
                                </div>
                            )}
                        </div>
                        <div className='pad-xs flex-column width-25'>
                            <p className='pad-b-xs'>Time of Day: </p>
                            <div className='flex'>
                                <label htmlFor='time_of_day' className='pad-r-xs'>Hour: </label>
                                <select className='pad-xs pointer' name='time_of_day' onChange={(e) => setTimeOfDay(e.target.value)}>
                                    <option value="">Select...</option>
                                    {Array.from({ length: 12 }, (_, i) => i + 1).map(hour => (
                                        <option key={hour} value={hour}>{hour}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='flex column-gap-md pad-t-sm'>
                                <div>
                                    <label htmlFor="am" className='pointer'>AM </label>
                                    <input type="radio" id="am" name="am_pm" value="AM" className='pointer' onChange={(e) => setMeridiem(e.target.value)}></input>
                                </div>
                                <div>
                                    <label htmlFor="pm" className='pointer'>PM </label>
                                    <input type="radio" id="pm" name="am_pm" value="PM" className='pointer' onChange={(e) => setMeridiem(e.target.value)}></input>
                                </div>
                            </div>
                        </div>
                        <div className='pad-xs flex-column width-25'>
                            <p className='pad-b-xs'>Recipients: </p>
                            <Select
                                isMulti
                                name="recipients"
                                options={(userDropdown || []).map(user => ({ value: user.userEmail, label: user.userName }))}
                                className='select-pointer'
                                value={recipients}
                                onChange={handleRecipientsChange}
                            />
                        </div>
                    </div>
                    <div className='flex justify-end'>
                        <Btn title='Cancel'
                            btnClass='bg-light-red delete-button text-white pad-lr-xlg text-small margin-r-sm b-none'
                            onClick={() => {
                                setReportType('')
                                setSelectedTimeframe('')
                                setFrequency('')
                                setWeeklyDay('')
                                setTimeOfDay('')
                                setMeridiem('')
                                setRecipients([{ value: 'marc@playgroundboss.com', label: 'Marc Parker' }])
                                setAddReport(false)
                            }} />
                        <Btn title="Save"
                            btnClass='bg-green green-button text-white pad-lr-lg text-small b-none'
                            onClick={() => onSave()}
                        />
                    </div>
                </div>
                : null
            }
            <div className='underline-light-gray'></div>
            <div className='width-100 '>
                <table className='width-100 border-collapse'>
                    <thead>
                        <tr className='underline-light-gray'>
                            <td></td>
                            <td className='text-bold pad-tb-sm'>Report Type</td>
                            <td className='text-bold margin-l-md pad-tb-sm pad-l-md pad-r-md'>Frequency</td>
                            <td className='text-bold margin-l-md pad-tb-sm pad-l-md pad-r-md'>Time of Day</td>
                            <td className='text-bold margin-l-md pad-tb-sm'>Recipients</td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody className='height-100'>
                        {reportsListed.map((report, index) => (
                            <tr key={index}>
                                <td>
                                    <Btn icon='trash' btnClass=' margin-r-md sm-box'
                                    onClick={() => deleteReport(report?.id)} />
                                </td>
                                <td className='pad-tb-sm'>{report.reportType}</td>
                                <td className='pad-tb-sm  pad-l-md pad-r-md'>{report.frequency}</td>
                                <td className='pad-tb-sm  pad-l-md pad-r-md'>{report.timeOfDay}</td>
                                <td className='pad-tb-sm'>{report.recipients}</td>
                                <td></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ReportAutomation;