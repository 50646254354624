import React, { useCallback, useEffect, useState } from 'react';
import config from '../../config';
import authHeaders from '../../utilities/authHeaders';
import Btn from '../../components/Btn';
import LoadingInline from '../../components/LoadingInline';
import IncomeAcc from './IncomeAcc.js';
import ExpenseAcc from './ExpenseAcc.js';
import { useStore } from '../../StoreProvider';
import { ACTIONS } from '../../Actions';
import convertJSON from '../../utilities/convertJSON';
import uploadIcon from '../../assets/upload.svg';
import xIcon from '../../assets/xicon.svg';
import GenericTable from '../table/GenericTable.js';
import xmark from '../../assets/xmark.svg';
import checkmark from '../../assets/checkmark.svg';
import { convertToRaw, ContentState, EditorState, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";


const Modal = ({
    modal,
    setModal,
    product,
    setProduct,
    setProducts,
    setLoading,
    setSearchValue,
    populateAccounts,
    incomeAccounts,
    expenseAccounts,
    categories,
    productVariants = [],
    manufacturers,
}) => {
    const { state, dispatch } = useStore();
    const forceUpdate = useCallback(() => setProduct({}));

    //Manufacturers dropdown State
    const [manufacturer, setManufacturer] = useState();

    //Filtered Variants for manufacturer
    const [filteredVariants, setFilteredVariants] = useState();

    //Four variant choices
    const [variant1, setVariant1] = useState();
    const [variant2, setVariant2] = useState();
    const [variant3, setVariant3] = useState();
    const [variant4, setVariant4] = useState();

    // Initialize EditorState
    let initialEditorState = product.web_description
    ? EditorState.createWithContent(htmlToDraft(product.web_description).contentBlocks.length
        ? ContentState.createFromBlockArray(htmlToDraft(product.web_description).contentBlocks)
        : ContentState.createFromText(""))
    : EditorState.createEmpty();

    const [editorState, setEditorState] = useState(initialEditorState);

    const [editingRow, setEditingRow] = useState(null); // Track the row being edited
    const [deleteRow, setDeleteRow] = useState(null); // Track the row being deleted
    const [altText, setAltText] = useState(null); // New state to track the edited value
    const [primaryImage, setPrimaryImage] = useState(null); // New state to track the edited value
    const [savedCells, setSavedCells] = useState({}); // Tracks saved cells, e.g., { 'rowIndex-columnKey': true }
    const [cancel, setCancel] = useState(false);

    //Clear Variants State
    const [clearVariants, setClearVariants] = useState(false);

    //Variants Order
    const variantsOrder = [variant1, variant2, variant3, variant4];

    //Set Default State for Toggles that enable text inputs
    const [hasFreeFreight, setHasFreeFreight] = useState();
    const [vendorPurchase, setVendorPurchase] = useState();

    //Product Quickbooks States
    const [categoryReference, setCategoryReference] = useState();
    const [loadingCategory, setLoadingCategory] = useState(true);

    //Auth
    const headers = authHeaders(state?.auth.accessToken, state?.auth.refreshToken);

    //Product State
    const [put, setPut] = useState({});

    //Error Message Default State
    const [duplicationError, setDuplicationError] = useState(false);
    const [productSkuError, setProductSkuError] = useState(false);
    const [productNameError, setProductNameError] = useState(false);
    const [costError, setCostError] = useState(false);
    const [salePriceError, setSalePriceError] = useState(false);
    const [opsCostError, setOpsCostError] = useState(false);
    const [msrpError, setMsrpError] = useState(false);
    const [webListedPriceError, setWebListedPriceError] = useState(false);
    const [showImage, setShowImage] = useState({ status: false, url: "" })

    useEffect(() => {
        if (product.web_description) {
            const blocksFromHtml = htmlToDraft(product.web_description);
            if (blocksFromHtml) {
                const { contentBlocks, entityMap } = blocksFromHtml;
                const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                setEditorState(EditorState.createWithContent(contentState));
            } else {
                setEditorState(EditorState.createEmpty());
            }
        }
    }, [product.web_description]);

    function handleViewImage(image) {
        let imageLink = image;

        // let imageType = ((imageLink).substring(imageLink.length - 3))
        // if (imageType === "pdf") {
        //     setShowImage({ status: true, url: imageLink, type: "pdf" });
        // } else {
        //     setShowImage({ status: true, url: imageLink, type: "image" });
        // }
        setShowImage({ status: true, url: imageLink, type: "image" });
    }

    function handleModal() {
        setShowImage({ status: false, url: "" })
    }

    const handleUpload = async (event) => {
        const file = event.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        formData.append("productName", product?.product_name);

        try {
            const res = await fetch(`${config.base_api}/images/product/${product?.playground_boss_sku}`,
            {
                method: 'POST',
                headers: authHeaders(state?.auth.accessToken, state?.auth.refreshToken, "formData"),
                body: formData
            });
            if (res.ok) {
                let response = await convertJSON(res);

                // Update the image links
                product.image_links = JSON.stringify(response);

                dispatch({
                    type: ACTIONS.TOAST, payload: {
                        message: 'Image uploaded successfully',
                        isError: false,
                    }
                });


                console.log("image uploaded")
                //connectImageData();
            } else if (res.status === 500) {
                dispatch({
                    type: ACTIONS.TOAST, payload: {
                        message: 'Could not upload image, internal server error',
                        isError: true,
                    }
                });
            } else if (res.status === 400) {
                dispatch({
                    type: ACTIONS.TOAST, payload: {
                        message: 'Image with the same name already exists.',
                        isError: true,
                    }
                });
            }
        } catch (error) {
            dispatch({
                type: ACTIONS.TOAST, payload: {
                    message: 'Could not upload image, please check file size and type',
                    isError: true,
                }
            });
            console.log(error)
        }
    }

    //Delete Product
    const deleteProduct = async (productID) => {
        try {
            const res = await fetch(`${config.base_api}/products/delete/${productID}`, {
                method: 'PUT',
                headers,
            });

            await convertJSON(res);

            if (res.ok) {
                dispatch({ type: ACTIONS.TOAST, payload: { message: `Product ${productID} Deleted` } });
                setLoading(true);
                setModal(false);
                setProducts({});
                setSearchValue('');
            }
        } catch (error) {
            dispatch({ type: ACTIONS.TOAST, payload: { message: error, isError: true } });
            console.log(error);
        }
    };

    //Update Product
    async function updateProduct(e) {
        e.preventDefault();
        console.log(put)

        if (modal === 'update') {
            try {
                const fieldsToUpdate = Object.keys(put);
                const shouldSyncQb =
                    !!product.needs_sync_qb ||
                    fieldsToUpdate.length > 1 ||
                    (fieldsToUpdate.length === 1 && fieldsToUpdate[0] !== 'variants_order');
                const shouldSyncBc =
                    !!product.needs_sync_bc ||
                    (fieldsToUpdate.includes('variants_order') && (!!product.manufacturer || !!put.manufacturer));

                if (put?.is_palette === false) {
                    put.per_palette = 0;
                }

                const update = {
                    ...put,
                    needs_sync_qb: shouldSyncQb || false,
                    needs_sync_bc: shouldSyncBc || false,
                    needs_sync_shopify: true,
                    modified_action: product?.modified_action === 'create' ? 'create' : 'update',
                };

                const res = await fetch(`${config.base_api}/products/${product.playground_boss_sku}`, {
                    method: 'PUT',
                    headers,
                    body: JSON.stringify(update),
                });

                await convertJSON(res);

                if (res.ok) {
                    dispatch({ type: ACTIONS.TOAST, payload: { message: `Product ${product.playground_boss_sku} Updated` } });
                    setLoading(true);
                    setModal(false);
                    setProducts({});
                }
            } catch (error) {
                dispatch({ type: ACTIONS.TOAST, payload: { message: error, isError: true } });
            } finally {
                await syncBigCommerce();
                await syncShopify();
                await syncQuickbooks();
            }
        }
    }

    //Add Product
    async function addProduct(e) {
        //Product Name
        var name = document.getElementById('product_name').value;
        //Product SKU
        var sku = document.getElementById('playground_boss_sku').value;
        //Product Cost
        var cost = document.getElementById('cost').value;
        //Product Sale Price
        var sale_price = document.getElementById('sale_price').value;
        //Product Ops Cost
        var ops_cost = document.getElementById('ops_cost').value;
        //Product Web Listed Price
        var web_listed_price = document.getElementById('web_listed_price').value;
        //Product MSRP
        var msrp = document.getElementById('msrp').value;

        //Require Product Name and Product Sku to create product
        if (name.length == 0) {
            setProductNameError(true);
        }
        if (sku.length == 0) {
            setProductSkuError(true);
        }

        // Require All Prices to have a value in them
        if (cost.length == 0) {
            setCostError(true);
        }
        if (sale_price.length == 0) {
            setSalePriceError(true);
        }
        if (ops_cost.length == 0) {
            setOpsCostError(true);
        }
        if (web_listed_price.length == 0) {
            setWebListedPriceError(true);
        }
        if (msrp.length == 0) {
            setMsrpError(true);
        }

        //Return if product sku or name is missing or if any price fields are empty
        if (
            sku.length == 0 ||
            name.length == 0 ||
            cost.length == 0 ||
            sale_price.length == 0 ||
            ops_cost.length == 0 ||
            web_listed_price.length == 0 ||
            msrp.length == 0
        ) {
            return;
        }

        try {
            if (put?.is_palette === false) {
                put.per_palette = 0;
            }

            const add = {
                ...put,
                // BC sync must be set to true for backend to autosync
                needs_sync_qb: false,
                needs_sync_bc: true,
                needs_sync_shopify: true,
                modified_action: 'create',
            };

            const res = await fetch(`${config.base_api}/products`, {
                method: 'POST',
                headers,
                body: JSON.stringify(add),
            });

            await convertJSON(res);

            if (res.ok) {
                dispatch({ type: ACTIONS.TOAST, payload: { message: `Product ${put.playground_boss_sku} Created` } });
                setLoading(true);
                setProducts({});
                setSearchValue('');
                setProductNameError('');
                setProductSkuError('');
                setCostError('');
                setSalePriceError('');
                setOpsCostError('');
                setWebListedPriceError('');
                setMsrpError('');
            }

            if (!res.ok) {
                throw new Error(res.status);
            }
        } catch (error) {
            if (error.message == 406) {
                setDuplicationError(true);
            }
        } finally {
            await syncBigCommerce();
            await syncShopify();
            await syncQuickbooks();
            setModal(false);
        }
    }

    const syncBigCommerce = async () => {
        try {
            let res = await fetch(`${config.base_api}/bigCommerce`);
            if (res.ok) {
                dispatch({ type: ACTIONS.TOAST, payload: { message: `Product Synced with BigCommerce` } });
                setModal(false);
            }
        } catch (error) {
            dispatch({ type: ACTIONS.TOAST, payload: { message: error, isError: true } });
        }
    };

    const syncShopify = async () => {
        try {
            let res = await fetch(`${config.base_api}/shopify`);
            if (res.ok) {
                dispatch({ type: ACTIONS.TOAST, payload: { message: `Product Synced with Shopify` } });
                setModal(false);
            }
        } catch (error) {
            dispatch({ type: ACTIONS.TOAST, payload: { message: error, isError: true } });
        }
    };

    const syncQuickbooks = async () => {
        try {
            let res = await fetch(`${config.base_api}/quickbooks/syncProducts/authURI/product_only`);
            if (res.ok) {
                dispatch({ type: ACTIONS.TOAST, payload: { message: `Product Synced with QuickBooks` } });
                setModal(false);
            }
        } catch (error) {
            dispatch({ type: ACTIONS.TOAST, payload: { message: error, isError: true } });
        }
    };

    useEffect(() => {
        if (manufacturer) {
            const filteredArray = productVariants.filter((variant) => variant.manufacturer === manufacturer);
            setFilteredVariants(filteredArray);
        }
    }, [manufacturer]);

    //If MODAL closed or add product clear manufacturer, categoryReference, variants, and all empty price field errors
    useEffect(() => {
        if (!modal || modal === 'add') {
            setManufacturer('');
            setCategoryReference('');
            setVariant1('');
            setVariant2('');
            setVariant3('');
            setVariant4('');
            setCostError('');
            setSalePriceError('');
            setOpsCostError('');
            setWebListedPriceError('');
            setMsrpError('');
        }
    }, [modal]);

    //Update variants order when variant state changes
    useEffect(() => {
        const results = variantsOrder.filter((element) => {
            return element !== '';
        });

        setPut({
            ...put,
            variants_order: `${results}`,
        });
    }, [variant1]);

    useEffect(() => {
        const results = variantsOrder.filter((element) => {
            return element !== '';
        });

        setPut({
            ...put,
            variants_order: `${results}`,
        });
    }, [variant2]);

    useEffect(() => {
        const results = variantsOrder.filter((element) => {
            return element !== '';
        });

        setPut({
            ...put,
            variants_order: `${results}`,
        });
    }, [variant3]);

    useEffect(() => {
        const results = variantsOrder.filter((element) => {
            return element !== '';
        });

        setPut({
            ...put,
            variants_order: `${results}`,
        });
    }, [variant4]);

    //Clear variants
    useEffect(() => {
        if (clearVariants) {
            setVariant1('');
            setVariant2('');
            setVariant3('');
            setVariant4('');
            console.log('clear variants');
            setClearVariants(false);
        }
    }, [clearVariants]);

    useEffect(() => {
        setManufacturer(product.manufacturer);
        setHasFreeFreight(product.has_free_freight);
        setVendorPurchase(product.qb_vendor_purchase);

        if (product.primary_category) {
            let category = categories.filter((category) => category.id === product.primary_category);

            if (category.length > 0) {
                setCategoryReference(category[0].reference);
                setLoadingCategory(false);
            }
        }

        if (product.variants_order) {
            console.log('hit switch');
            var variants = product.variants_order.split(',');

            for (let i = 0; i < variants.length; i++) {
                switch (i) {
                    case 0:
                        console.log('hit variant1');
                        setVariant1(variants[i]);
                        break;
                    case 1:
                        setVariant2(variants[i]);
                        break;
                    case 2:
                        setVariant3(variants[i]);
                        break;
                    case 3:
                        setVariant4(variants[i]);
                        break;
                }
            }
        }
    }, [product]);

    if (!modal) {
        return null;
    }

    function isValidProduct(obj) {
        const validSkus = ['PGBTB-5208-KT', 'PGBTB-5212-KT', 'PGBTB-5208-APS', 'PGBTB-5212-APS'];
        const validManufacturers = ['KidsTale Playgrounds', 'KidsTale Inc', 'Barks and Rec', 'Sports Play', 'MyTCoat'];
    
        const skuMatch = validSkus.includes(obj.playground_boss_sku);
        const manufacturerMatch = validManufacturers.includes(obj.manufacturer)
    
        return skuMatch || manufacturerMatch;
    }

    const deleteImage = async (imageLink, row) => {
        try {
            const updateImageLinks = JSON.parse(product.image_links);
            let newImageLinks = updateImageLinks.filter((image) => image.url?.Location !== imageLink);
            const res = await fetch(`${config.base_api}/products/updateImage/${product.playground_boss_sku}`, {
                method: 'PUT',
                headers: authHeaders(state?.auth.accessToken, state?.auth.refreshToken),
                body: JSON.stringify(newImageLinks),
            });

            if (res.ok) {
                dispatch({
                    type: ACTIONS.TOAST, payload: {
                        message: 'Image deleted',
                        isError: false,
                    }
                });

                const data = await convertJSON(res);
            } else if (res.status === 400) {
                dispatch({
                    type: ACTIONS.TOAST, payload: {
                        message: 'Primary image cannot be deleted',
                        isError: true,
                    }
                });
                throw new Error('Failed to delete image');
            } else {
                dispatch({
                    type: ACTIONS.TOAST, payload: {
                        message: 'Primary image cannot be deleted',
                        isError: true,
                    }
                });
                throw new Error('Failed to delete image');

            }

            product.image_links = JSON.stringify(newImageLinks);
            imageLinks = newImageLinks.map((image) => ({
                'image': image.url?.Location,
                thumbnail: image.thumbnail,
                alt: image.alt,
            }));


        } catch (error) {
            console.log(error);
        }
    };

    // Adjusted to be a function that accepts a row and returns actions for that row
    const actions = (row) => {
        return [
        ...editSaveActions(row),
        ];
    };

    const editSaveActions = (row) => {
    // Actions when a row is being edited
    if (editingRow === row.imageLink) {
        return [
        {
            label: 'Cancel',
            onMouseDown: () => setCancel(true), // Set cancel state to true on mouse down
            handler: () => {
                setEditingRow(null); // Exit editing mode without saving
                setAltText(null);
                setPrimaryImage(null);
            },
            buttonClass: 'btn-cancel',
        }
        ];
    } else if (deleteRow === row.imageLink) {
        return [
            {
                label: 'Confirm Delete',
                handler: () => {
                    deleteImage(row.imageLink, row);
                    setDeleteRow(null);
                },
                buttonClass: 'btn-delete',
            },
            {
                label: 'Cancel',
                handler: () => {
                    setDeleteRow(null);
                },
                buttonClass: 'btn-cancel',
            }
        ]
    }
    // Default action when not editing
    else {
        return [
            {
                label: 'Edit',
                handler: () => {
                    setEditingRow(row.imageLink); // Set this row to be in editing mode
                    setAltText(row.alt);
                    setPrimaryImage(row.thumbnail);
                    setCancel(false); // Reset cancel state
                },
                buttonClass: 'btn-edit',
            },
            {
                label: 'Delete',
                handler: () => {
                    setDeleteRow(row.imageLink);
                },
                buttonClass: 'btn-delete',
            },
        ];
    }
    };  
    
    const handleBlur = (imageLink, columnKey, value, sku) => {
        if (!cancel) {
            handleSave(imageLink, columnKey, value, sku);
        } 
        else {
            setCancel(false);
        }
    };

    const handleSave = async (imageLink, columnKey, newValue, sku) => {
        // Implement your save logic here
        if (columnKey === 'alt') {
            setEditingRow(null); // Exit editing mode
            await updateAltText(imageLink, newValue);
        } else if (columnKey === 'thumbnail') {
            setEditingRow(null); // Exit editing mode
            await updatePrimaryImage(imageLink, newValue);
        }    
        // Update the savedCells state to mark this cell as saved
        const cellId = `${sku}-${columnKey}`;
        setSavedCells({ ...savedCells, [cellId]: true });
    
        // Optionally, clear the saved indicator after a delay
        setTimeout(() => setSavedCells((prev) => ({ ...prev, [cellId]: false })), 2000); // Clear after 2 seconds
      };

    const updatePrimaryImage = async (imageLink, newValue) => {
        try {
            // Search through the imageLinks array to find the imageLink that matches the one being edited and update the alt text for only that one
            const updateImageLinks = JSON.parse(product.image_links);
            let newImageLinks = updateImageLinks.map((image) => {
                if (image?.url?.Location === imageLink) {
                    return { ...image, is_thumbnail: newValue };
                }
                return { ...image, is_thumbnail: false };
                
            });
            
            const res = await fetch(`${config.base_api}/products/updateImage/${product.playground_boss_sku}`, {
                method: 'PUT',
                headers: authHeaders(state?.auth.accessToken, state?.auth.refreshToken),
                body: JSON.stringify(newImageLinks),
            });

            if (!res.ok) throw new Error('Failed to update primary image');

            product.image_links = JSON.stringify(newImageLinks);
            imageLinks = newImageLinks.map((image) => ({
                'image': image.url?.Location,
                thumbnail: image.thumbnail,
                alt: image.alt,
            }));

            const data = await convertJSON(res);
        } catch (error) {
            console.log(error);
        }
    };

    const updateAltText = async (imageLink, newValue) => {
        try {
            // Search through the imageLinks array to find the imageLink that matches the one being edited and update the alt text for only that one
            const updateImageLinks = JSON.parse(product.image_links);
            let newImageLinks = updateImageLinks.map((image) => {
                if (image?.url?.Location === imageLink) {
                    return { ...image, alt: newValue };
                }
                return image;
            });

            const res = await fetch(`${config.base_api}/products/updateImage/${product.playground_boss_sku}`, {
                method: 'PUT',
                headers: authHeaders(state?.auth.accessToken, state?.auth.refreshToken),
                body: JSON.stringify(newImageLinks),
            });

            if (!res.ok) throw new Error('Failed to update alt text');

            product.image_links = JSON.stringify(newImageLinks);
            imageLinks = newImageLinks.map((image) => ({
                'image': image.url?.Location,
                thumbnail: image.thumbnail,
                alt: image.alt,
            }));

            const data = await convertJSON(res);
        } catch (error) {
            console.log(error);
        }
    };

    let imageLinks = product?.image_links ? JSON.parse(product.image_links) : [];
    imageLinks = imageLinks.map((image, index) => ({
        'sku': index,
        'thumbnail': image?.is_thumbnail,
        'pgb_sku': product.playground_boss_sku,
        'image': <div>
            <img
                src={image.url?.Location} // Use the image URL from the parsed object
                className='width-30 height-30 pad-r-xs'
                alt={image.alt || 'thumbnail'} // Use alt text or default to "thumbnail"
            />
                <button
                    key={`${product.product_name}-view-image-${index}`}
                    onClick={() => handleViewImage(image.url?.Location)} // Pass the image URL to the handler
                    className="flex w-30 align-center border-green justify-center bg-white border-radius-xs  text-green pad-xs pointer margin-l-sm"
                >
                    View
                </button>
            </div>,
        'alt': image?.alt,
        'imageLink': image.url?.Location,
    }));
    const columns = [
        { 
            key: 'thumbnail', 
            label: 'Thumbnail', 
            headerClass: 'pad-l-xxlg text-left', 
            dataClass: ' text-left pad-l-xxlg',
            checkmarkDisabled: true,
            renderCell: (row) => {
                // Radio buttons for selecting the primary image
                if (editingRow === row.imageLink) {
                    return (
                        <div className='flex align-center'>
                            <input type="radio" name="primaryImage" 
                                checked={primaryImage} 
                                onChange={(e) => setPrimaryImage(e.target.value)}
                                onBlur={(e) => handleBlur(row.imageLink, 'thumbnail', e.target.value, row.sku)}
                            />
                        </div>
                    );
                } else {
                    if (!row.thumbnail) {
                        return  <span className='table-cell text-end pad-r-xs'><img src={xmark} className=' text-xs height-1dot5em' /></span>
                    } else { 
                        return <span className='table-cell text-end pad-r-xs '><img src={checkmark} className='text-xs height-1dot3em' /></span>
                    }
                }
            }
        },
        { key: 'image', label: 'Image', headerClass: 'pad-l-xxlg text-left', dataClass: '200px text-left pad-l-xxlg' },
        { 
            key: 'alt', 
            label: 'Alt Text', 
            headerClass: 'pad-l-xxlg text-left', 
            dataClass: '200px text-left pad-l-xxlg',
            renderCell: (row) => {
                if (editingRow === row.imageLink) {
                    return (
                        <textarea
                            type="text"
                            value={altText}
                            onChange={(e) => setAltText(e.target.value)}
                            onBlur={(e) => handleBlur(row.imageLink, 'alt', e.target.value, row.sku)}
                        />
                    );
                } else {
                    return row.alt;
                }
            }

        },
    ];

    return (
        <div id="productsModal">
            <span
                className="close"
                onClick={() => {
                    setModal((prevClick) => !prevClick);
                    forceUpdate();
                    setLoadingCategory(true);
                    setDuplicationError(false);
                    setProductNameError(false);
                    setProductSkuError(false);
                    setPut({});
                }}>
                <b className="text-xxl text-red-hover">X</b>
            </span>
            {modal === 'add' ? (
                <div id="productTitle">
                    <h4>Add Product</h4>
                    <div className='flex align-center margin-l-auto margin-r-xxlg pad-b-md column-gap-sm'>
                        <input type="checkbox" onChange={(e) => { setPut({ ...put, exclude_from_installation: e.target.checked }) }}></input>
                        <label>Exclude from installation cost</label>
                        <input type='checkbox' onChange={(e) => { setPut({ ...put, no_display: e.target.checked }) }} className='margin-l-lg'></input>
                        <label>Do not display in Quote Tool</label>
                    </div>
                </div>
            ) : (
                <div id="productTitle" className='flex'>
                    <h4>{product.playground_boss_sku}</h4>
                    <h3>{product.product_name}</h3>
                    <div onClick={() => deleteProduct(product.playground_boss_sku)}>
                        <Btn title="Delete" btnClass="pad-lr-lg pad-tb-md bg-light-red delete-button b-none text-white text-bold box-shadow" />
                    </div>
                    <div className='flex align-center margin-l-auto margin-r-xxlg pad-b-md column-gap-sm'>
                        <input type="checkbox" defaultChecked={product.exclude_from_installation}
                            onChange={(e) => { setPut({ ...put, exclude_from_installation: e.target.checked }) }}></input>
                        <label>Exclude from installation cost</label>
                        <input type='checkbox' defaultChecked={product.no_display} className='margin-l-lg'
                            onChange={(e) => { setPut({ ...put, no_display: e.target.checked }) }}></input>
                        <label>Do not display in Quote Tool</label>
                    </div>
                </div>
            )}
            <div id="productContainer">
                <div className="quotesTable">
                    <table>
                        <thead>
                            <tr className="headBorder"></tr>
                        </thead>
                        <tbody>
                            <tr>
                                {/*Product SKU*/}
                                <td>SKU:</td>
                                {modal === 'add' ? (
                                    !duplicationError ? (
                                        <td>
                                            <input
                                                className="inputWidth"
                                                id="playground_boss_sku"
                                                onBlur={(e) => {
                                                    if (e.target.value.length > 0) {
                                                        setProductSkuError(false);
                                                    } else {
                                                        setProductSkuError(true);
                                                    }
                                                    setPut({ ...put, playground_boss_sku: e.target.value });
                                                }}></input>
                                            {!productSkuError ? (
                                                ''
                                            ) : (
                                                <b>
                                                    <span className="error"> Product SKU is required </span>
                                                </b>
                                            )}
                                        </td>
                                    ) : (
                                        <td>
                                            <input
                                                className="inputWidth"
                                                id="playground_boss_sku"
                                                onBlur={(e) => {
                                                    setProductSkuError(e.target.value);
                                                    setPut({ ...put, playground_boss_sku: e.target.value });
                                                }}></input>
                                            <br />
                                            <b>
                                                <span className="error"> This product SKU already exists! </span>
                                            </b>
                                        </td>
                                    )
                                ) : (
                                    <td id="playground_boss_sku">{product.playground_boss_sku}</td>
                                )}

                                {/*Product Name*/}
                                <td className="flex align-center">Product Name:
                                    <span className="tooltip tooltip-icon">
                                        <span className="tooltiptext double-orange-border line-height-sm modal-tooltip modal-tooltiptext">
                                            Once saved, Product Name cannot be edited because it is an identifier with Quickbooks API.
                                        </span>
                                    </span>
                                </td>
                                {modal === 'add' ? (
                                    <td colSpan="3">
                                        <input
                                            className="inputWidth"
                                            id="product_name"
                                            onBlur={(e) => {
                                                if (e.target.value.length > 0) {
                                                    setProductNameError(false);
                                                } else {
                                                    setProductNameError(true);
                                                }
                                                setPut({ ...put, product_name: e.target.value });
                                            }}></input>
                                        {!productNameError ? (
                                            ''
                                        ) : (
                                            <b>
                                                <span className="error"> Product Name is required </span>
                                            </b>
                                        )}
                                    </td>
                                ) : (
                                    <td id="product_name" colSpan="3">
                                        {product.product_name}
                                    </td>
                                )}
                            </tr>
                            <tr>
                                <td>Manufacturer SKU:</td>
                                {/*MAnufacturer SKU*/}
                                {modal === 'add' ? (
                                    <td>
                                        <input
                                            required
                                            className="inputWidth"
                                            id="manufacturer_sku"
                                            onBlur={(e) => {
                                                setPut({ ...put, manufacturer_sku: e.target.value });
                                            }}></input>
                                    </td>
                                ) : (
                                    <td>
                                        <input
                                            required
                                            className="inputWidth"
                                            id="manufacturer_sku"
                                            defaultValue={product.manufacturer_sku}
                                            onBlur={(e) => setPut({ ...put, manufacturer_sku: e.target.value })}></input>
                                    </td>
                                )}

                                {/*MANUFACTURER*/}
                                <td>Manufacturer:</td>
                                <td colSpan="3">
                                    <select
                                        name="variantsList"
                                        id="manufacturers"
                                        placeholder="Select Manufacturer"
                                        value={manufacturer}
                                        onChange={(e) => {
                                            setManufacturer(e.target.value);
                                            setPut({ ...put, manufacturer: e.target.value });
                                            setClearVariants(true);
                                        }}
                                        className="inputWidth">
                                        <option value={null}></option>
                                        {manufacturers.map((item, i) =>
                                            item ? (
                                                <option value={item.vendor} key={i}>
                                                    {item.vendor}
                                                </option>
                                            ) : null
                                        )}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                {/*Quote Tool Description*/}
                                <td> Quote Tool Description:</td>
                                {modal === 'add' ? (
                                    <td colSpan="5">
                                        <textarea
                                            id="product_description"
                                            onBlur={(e) => setPut({ ...put, product_description: e.target.value })}
                                        />
                                    </td>
                                ) : (
                                    <td colSpan="5">
                                        <textarea
                                            id="product_description"
                                            defaultValue={product.product_description}
                                            onBlur={(e) => setPut({ ...put, product_description: e.target.value })}
                                        />
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {/* Quote Tool Web (Shopify) Description */}
                                <td> Quote Tool Web Description:</td>

                                <td colSpan="5">        

                                    {/* Rich Text Editor */}
                                    <Editor
                                        editorState={editorState}
                                        onEditorStateChange={(state) => {
                                            setEditorState(state);

                                            // Save as HTML in `web_description`
                                            const htmlContent = draftToHtml(convertToRaw(state.getCurrentContent()));
                                            setPut({ ...put, web_description: htmlContent });
                                        }}
                                        wrapperClassName="wysiwyg-wrapper-class"
                                        editorClassName="wysiwyg-editor-class"
                                        toolbarClassName="toolbar-class"
                                        placeholder="Enter description here..."
                                        toolbar={{
                                            options: ["inline", "blockType", "fontSize", "list", "textAlign", "link", "history"],
                                            inline: { options: ["bold", "italic", "underline"] },
                                            list: { options: ["unordered", "ordered"] },
                                            textAlign: { options: ["left", "center", "right"] },
                                        }}
                                    />
                                </td>
                            </tr>
                            <tr>
                                {/*Cost*/}
                                <td>Cost:</td>
                                {modal === 'add' ? (
                                    <td>
                                        {!costError ? (
                                            <></>
                                        ) : (
                                            <b>
                                                <span className="error">Cost cannot be blank.</span>
                                            </b>
                                        )}
                                        <input
                                            className="inputWidth"
                                            id="cost"
                                            onBlur={(e) => {
                                                if (e.target.value.length > 0) {
                                                    setCostError(false);
                                                } else {
                                                    setCostError(true);
                                                }
                                                setPut({ ...put, cost: e.target.value });
                                            }}></input>
                                    </td>
                                ) : (
                                    <td>
                                        <input
                                            className="inputWidth"
                                            defaultValue={product.cost}
                                            id="cost"
                                            onBlur={(e) => setPut({ ...put, cost: e.target.value })}></input>
                                    </td>
                                )}

                                {/*Sale Price*/}
                                <td>Sale Price:</td>
                                {modal === 'add' ? (
                                    <td>
                                        {!salePriceError ? (
                                            <></>
                                        ) : (
                                            <b>
                                                <span className="error">Sale Price cannot be blank.</span>
                                            </b>
                                        )}
                                        <input
                                            className="inputWidth"
                                            id="sale_price"
                                            onBlur={(e) => {
                                                if (e.target.value.length > 0) {
                                                    setSalePriceError(false);
                                                } else {
                                                    setSalePriceError(true);
                                                }
                                                setPut({ ...put, sale_price: e.target.value });
                                            }}></input>
                                    </td>
                                ) : (
                                    <td>
                                        <input
                                            className="inputWidth"
                                            id="sale_price"
                                            defaultValue={product.sale_price}
                                            onBlur={(e) => setPut({ ...put, sale_price: e.target.value })}></input>
                                    </td>
                                )}

                                {/*Ops Cost*/}
                                <td>Ops Cost</td>
                                {modal === 'add' ? (
                                    <td>
                                        {!opsCostError ? (
                                            <></>
                                        ) : (
                                            <b>
                                                <span className="error">Ops Cost cannot be blank.</span>
                                            </b>
                                        )}
                                        <input
                                            className="inputWidth"
                                            id="ops_cost"
                                            onBlur={(e) => {
                                                if (e.target.value.length > 0) {
                                                    setOpsCostError(false);
                                                } else {
                                                    setOpsCostError(true);
                                                }
                                                setPut({ ...put, ops_cost: e.target.value });
                                            }}></input>
                                    </td>
                                ) : (
                                    <td>
                                        <input
                                            className="inputWidth"
                                            id="ops_cost"
                                            defaultValue={product.ops_cost}
                                            onBlur={(e) => setPut({ ...put, ops_cost: e.target.value })}></input>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {/*Web Listed Price*/}
                                <td>Web Listed Price:</td>
                                {modal === 'add' ? (
                                    <td colSpan="2">
                                        {!webListedPriceError ? (
                                            <></>
                                        ) : (
                                            <b>
                                                <span className="error">Web Listed Price cannot be blank.</span>
                                            </b>
                                        )}
                                        <input
                                            className="inputWidth"
                                            id="web_listed_price"
                                            onBlur={(e) => {
                                                if (e.target.value.length > 0) {
                                                    setWebListedPriceError(false);
                                                } else {
                                                    setWebListedPriceError(true);
                                                }
                                                setPut({ ...put, web_listed_price: e.target.value });
                                            }}></input>
                                    </td>
                                ) : (
                                    <td colSpan="2">
                                        <input
                                            className="inputWidth"
                                            id="web_listed_price"
                                            defaultValue={product.web_listed_price}
                                            onBlur={(e) => setPut({ ...put, web_listed_price: e.target.value })}></input>
                                    </td>
                                )}

                                {/*Is Palette*/}
                                {modal === 'add' ? (
                                    <td colSpan="1">
                                        <label htmlFor="is_palette">Is Palette: </label>
                                        <input
                                            type="checkbox"
                                            id="is_palette"
                                            onChange={(e) => setPut({ ...put, is_palette: e.target.checked })}></input>
                                    </td>
                                ) : (
                                    <td colSpan="1">
                                        <label htmlFor="is_palette">Is Palette: </label>
                                        <input
                                            type="checkbox"
                                            id="is_palette"
                                            defaultChecked={product.is_palette}
                                            onChange={(e) => setPut({ ...put, is_palette: e.target.checked })}
                                        ></input>
                                    </td>
                                )}
                                {
                                    (((put.is_palette === true) || (product.is_palette && put.is_palette !== false)) &&(isValidProduct(product) || isValidProduct(put)))
                                     ? (
                                        <>
                                            {modal === 'add' ? (
                                                <td colSpan="1">
                                                    <label className='line-height-normal margin-r-sm'>Per Palette: </label>
                                                    <input
                                                        type="number"
                                                        className="paletteInputWidth"
                                                        id="per_palette"
                                                        onBlur={(e) => setPut({ ...put, per_palette: e.target.value })}
                                                        onChange={(e) => {
                                                            if (e.target.value.includes('.')) {
                                                                e.target.value = parseInt(e.target.value, 10);
                                                            }
                                                        }}
                                                    ></input>
                                                </td>
                                            ) : (
                                                <td colSpan="1" >
                                                    <label className='line-height-normal margin-r-sm'>Per Palette: </label>
                                                    {
                                                        (
                                                            product.playground_boss_sku === 'PGBTB-5208-KT' ||
                                                            product.playground_boss_sku === 'PGBTB-5212-KT' ||
                                                            product.playground_boss_sku === 'PGBTB-5208-APS' ||
                                                            product.playground_boss_sku === 'PGBTB-5212-APS'
                                                        ) ?
                                                            <p className='line-height-normal display-inline'>42</p>
                                                            :
                                                            <input
                                                                type="number"
                                                                className="paletteInputWidth"
                                                                id="per_palette"
                                                                defaultValue={product.per_palette}
                                                                onBlur={(e) => setPut({ ...put, per_palette: e.target.value })}
                                                                onChange={(e) => {
                                                                    if (e.target.value.includes('.')) {
                                                                        e.target.value = parseInt(e.target.value, 10);
                                                                    }
                                                                }}
                                                            ></input>
                                                    }
                                                </td>
                                            )}
                                            <td>
                                                <label htmlFor="no_crate">No Crate: </label>
                                                {modal === 'add' ? (
                                                    <input
                                                        type="checkbox"
                                                        id="no_crate"
                                                        onChange={(e) => setPut({ ...put, no_crate: e.target.checked })}></input>
                                                ) : (
                                                    <input
                                                        type="checkbox"
                                                        id="no_crate"
                                                        defaultChecked={product.no_crate}
                                                        onChange={(e) => setPut({ ...put, no_crate: e.target.checked })}
                                                    ></input>
                                                )}
                                            </td>
                                        </>
                                    ) : <></>
                                }


                            </tr>
                            <tr>
                                {/*MSRP*/}
                                <td>MSRP:</td>
                                {modal === 'add' ? (
                                    <td>
                                        {!msrpError ? (
                                            <></>
                                        ) : (
                                            <b>
                                                <span className="error">MSRP Price cannot be blank.</span>
                                            </b>
                                        )}
                                        <input
                                            className="inputWidth"
                                            id="msrp"
                                            onBlur={(e) => {
                                                if (e.target.value.length > 0) {
                                                    setMsrpError(false);
                                                } else {
                                                    setMsrpError(true);
                                                }
                                                setPut({ ...put, msrp: e.target.value });
                                            }}></input>
                                    </td>
                                ) : (
                                    <td>
                                        <input
                                            className="inputWidth"
                                            id="msrp"
                                            defaultValue={product.msrp}
                                            onBlur={(e) => setPut({ ...put, msrp: e.target.value })}></input>
                                    </td>
                                )}

                                {/*CRATES*/}
                                <td>Crates</td>
                                {modal === 'add' ? (
                                    <td>
                                        <input
                                            className="inputWidth"
                                            id="crates"
                                            onChange={(e) => setPut({ ...put, crates: e.target.value })}></input>
                                    </td>
                                ) : (
                                    <td>
                                        <input
                                            className="inputWidth"
                                            id="crates"
                                            defaultValue={product.crates}
                                            onChange={(e) => setPut({ ...put, crates: e.target.value })}></input>
                                    </td>
                                )}

                                {/*SAFETY ZONE*/}
                                <td>Safety Zone:</td>
                                {modal === 'add' ? (
                                    <td>
                                        <input
                                            className="inputWidth"
                                            id="safety_use_zone"
                                            onChange={(e) => setPut({ ...put, safety_use_zone: e.target.value })}></input>
                                    </td>
                                ) : (
                                    <td>
                                        <input
                                            className="inputWidth"
                                            id="safety_use_zone"
                                            defaultValue={product.safety_use_zone}
                                            onChange={(e) => setPut({ ...put, safety_use_zone: e.target.value })}></input>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                {/*ACTIVITES*/}
                                <td>Activities:</td>
                                {modal === 'add' ? (
                                    <td>
                                        <input
                                            className="inputWidth"
                                            id="activities"
                                            onChange={(e) => setPut({ ...put, activities: e.target.value })}></input>
                                    </td>
                                ) : (
                                    <td>
                                        <input
                                            className="inputWidth"
                                            id="activities"
                                            defaultValue={product.activities}
                                            onChange={(e) => setPut({ ...put, activities: e.target.value })}></input>
                                    </td>
                                )}

                                {/*AGES*/}
                                <td>Ages:</td>
                                {modal === 'add' ? (
                                    <td>
                                        <input
                                            className="inputWidth"
                                            id="ages"
                                            onChange={(e) => setPut({ ...put, ages: e.target.value })}></input>
                                    </td>
                                ) : (
                                    <td>
                                        <input
                                            className="inputWidth"
                                            id="ages"
                                            defaultValue={product.ages}
                                            onChange={(e) => setPut({ ...put, ages: e.target.value })}></input>
                                    </td>
                                )}

                                {/*CAPACTIY*/}
                                <td>Capacity:</td>
                                {modal === 'add' ? (
                                    <td>
                                        <input
                                            className="inputWidth"
                                            id="child_capacity"
                                            onChange={(e) => setPut({ ...put, child_capacity: e.target.value })}></input>
                                    </td>
                                ) : (
                                    <td>
                                        <input
                                            className="inputWidth"
                                            id="child_capacity"
                                            defaultValue={product.child_capacity}
                                            onChange={(e) => setPut({ ...put, child_capacity: e.target.value })}></input>
                                    </td>
                                )}
                            </tr>
                            <tr>
                                <td colSpan="6">
                                    <b>Toggle Has Free Freight to 'true' to enable Freight Cost</b>
                                </td>
                            </tr>
                            <tr>
                                {/*HAS FREE FREIGHT*/}
                                <td colSpan="3">
                                    <label htmlFor="has_free_freight">Has Free Freight: </label>
                                    <input
                                        type="checkbox"
                                        id="has_free_freight"
                                        defaultChecked={product.has_free_freight}
                                        onChange={(e) => {
                                            setHasFreeFreight(e.target.checked);
                                            setPut({ ...put, has_free_freight: e.target.checked });
                                        }}></input>
                                </td>

                                {/*FREIGHT COST*/}
                                <td>Freight Cost:</td>
                                {hasFreeFreight ? (
                                    modal === 'add' ? (
                                        <td colSpan="2">
                                            <input
                                                className="inputWidth"
                                                id="freight_cost"
                                                onChange={(e) =>
                                                    setPut({ ...put, freight_cost: e.target.value })
                                                }></input>
                                        </td>
                                    ) : (
                                        <td colSpan="2">
                                            <input
                                                className="inputWidth"
                                                id="freight_cost"
                                                defaultValue={product.freight_cost}
                                                onChange={(e) =>
                                                    setPut({ ...put, freight_cost: e.target.value })
                                                }></input>
                                        </td>
                                    )
                                ) : modal === 'add' ? (
                                    <td colSpan="2">
                                        <input
                                            className="inputWidth"
                                            id="freight_cost"
                                            value=""
                                            disabled={true}></input>
                                    </td>
                                ) : (
                                    <td colSpan="2">
                                        <input
                                            className="inputWidth"
                                            id="freight_cost"
                                            disabled={true}
                                            defaultValue={product.freight_cost}></input>
                                    </td>
                                )}
                            </tr>
                        </tbody>
                        <thead>
                            {manufacturer ? (
                                filteredVariants && filteredVariants.length > 0 ? (
                                    //If manufacturer AND filtered VAriants Show Variants
                                    <>
                                        <tr className="headBorder">
                                            <td colSpan="2">
                                                <b>Product Variants</b>
                                            </td>
                                        </tr>
                                        <tr>
                                            {/*VARIANT 1*/}
                                            <td colSpan="3">
                                                <select
                                                    name="variantsList1"
                                                    id="variants1"
                                                    placeholder="Select Variant"
                                                    value={variant1}
                                                    onChange={(e) => setVariant1(e.target.value)}
                                                    className="inputWidth">
                                                    <option value={null}></option>
                                                    {filteredVariants
                                                        .filter(
                                                            (variant) =>
                                                                variant.id !== parseInt(variant2) &&
                                                                variant.id !== parseInt(variant3) &&
                                                                variant.id !== parseInt(variant4)
                                                        )
                                                        .map((item, i) => (
                                                            <option value={item.id} key={i}>
                                                                {' '}
                                                                {item.attribute} - {item.options}{' '}
                                                            </option>
                                                        ))}
                                                </select>
                                                <p>
                                                    {filteredVariants
                                                        .filter((variant) => variant.id === parseInt(variant1))
                                                        .map((item, i) => (
                                                            <span key={i}>
                                                                {' '}
                                                                {item.attribute} - {item.options}{' '}
                                                            </span>
                                                        ))}
                                                </p>
                                            </td>

                                            {/*VARIANT 2*/}
                                            <td colSpan="3">
                                                <select
                                                    name="variantsList2"
                                                    id="variants2"
                                                    placeholder="Select Variant"
                                                    value={variant2}
                                                    onChange={(e) => setVariant2(e.target.value)}
                                                    className="inputWidth">
                                                    <option value={null}></option>
                                                    {filteredVariants
                                                        .filter(
                                                            (variant) =>
                                                                variant.id !== parseInt(variant1) &&
                                                                variant.id !== parseInt(variant3) &&
                                                                variant.id !== parseInt(variant4)
                                                        )
                                                        .map((item, i) => (
                                                            <option value={item.id} key={i}>
                                                                {' '}
                                                                {item.attribute} - {item.options}{' '}
                                                            </option>
                                                        ))}
                                                </select>
                                                <p>
                                                    {filteredVariants
                                                        .filter((variant) => variant.id === parseInt(variant2))
                                                        .map((item, i) => (
                                                            <span key={i}>
                                                                {' '}
                                                                {item.attribute} - {item.options}{' '}
                                                            </span>
                                                        ))}
                                                </p>
                                            </td>
                                        </tr>
                                        <tr>
                                            {/*VARIANT 3*/}
                                            <td colSpan="3">
                                                <select
                                                    name="variantsList3"
                                                    id="variants3"
                                                    placeholder="Select Variant"
                                                    value={variant3}
                                                    onChange={(e) => setVariant3(e.target.value)}
                                                    className="inputWidth">
                                                    <option value={null}></option>
                                                    {filteredVariants
                                                        .filter(
                                                            (variant) =>
                                                                variant.id !== parseInt(variant1) &&
                                                                variant.id !== parseInt(variant2) &&
                                                                variant.id !== parseInt(variant4)
                                                        )
                                                        .map((item, i) => (
                                                            <option value={item.id} key={i}>
                                                                {' '}
                                                                {item.attribute} - {item.options}{' '}
                                                            </option>
                                                        ))}
                                                </select>
                                                <p>
                                                    {filteredVariants
                                                        .filter((variant) => variant.id === parseInt(variant3))
                                                        .map((item, i) => (
                                                            <span key={i}>
                                                                {' '}
                                                                {item.attribute} - {item.options}{' '}
                                                            </span>
                                                        ))}
                                                </p>
                                            </td>

                                            {/*VARIANT 4*/}
                                            <td colSpan="3">
                                                <select
                                                    name="variantsList4"
                                                    id="variants4"
                                                    placeholder="Select Variant"
                                                    value={variant4}
                                                    onChange={(e) => setVariant4(e.target.value)}
                                                    className="inputWidth">
                                                    <option value={null}></option>
                                                    {filteredVariants
                                                        .filter(
                                                            (variant) =>
                                                                variant.id !== parseInt(variant1) &&
                                                                variant.id !== parseInt(variant2) &&
                                                                variant.id !== parseInt(variant3)
                                                        )
                                                        .map((item, i) => (
                                                            <option value={item.id} key={i}>
                                                                {' '}
                                                                {item.attribute} - {item.options}{' '}
                                                            </option>
                                                        ))}
                                                </select>
                                                <p>
                                                    {filteredVariants
                                                        .filter((variant) => variant.id === parseInt(variant4))
                                                        .map((item, i) => (
                                                            <span key={i}>
                                                                {' '}
                                                                {item.attribute} - {item.options}{' '}
                                                            </span>
                                                        ))}
                                                </p>
                                            </td>
                                        </tr>
                                    </>
                                ) : (
                                    //If no variants associated with manufacturer
                                    <tr className="headBorder">
                                        <td colSpan="6">
                                            <b>Product Variants</b>
                                            <br />
                                            <b>No variants associated with this manufacturer</b>
                                        </td>
                                    </tr>
                                )
                            ) : (
                                <>
                                    <tr className="headBorder">
                                        <td colSpan="6">
                                            <b>Product Variants</b>
                                            <br />
                                            <b>Please select a manufacturer to enable product variants</b>
                                        </td>
                                    </tr>
                                </>
                            )}
                        </thead>

                        <thead>
                            <tr className="headBorder">
                                <td colSpan="2">
                                    <b>Quickbooks</b>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                {/*TYPE*/}
                                <td>Type:</td>
                                <td colSpan="2">
                                    <input
                                        placeholder="Select Type"
                                        className="inputWidth"
                                        id="qb_type"
                                        defaultValue={product.qb_type}
                                        onChange={(e) => setPut({ ...put, qb_type: e.target.value })}
                                        list="qb_type_list"
                                    />
                                    <datalist id="qb_type_list">
                                        <option value="Service" />
                                        <option value="NonInventory" />
                                    </datalist>
                                </td>

                                {/*CATEGORY*/}
                                <td>Category</td>
                                <td colSpan="2">
                                    <input
                                        className="inputWidth"
                                        list="categories"
                                        id="categories-input"
                                        name="categories-input"
                                        placeholder="Select Category"
                                        defaultValue={categoryReference}
                                        onChange={(e) => {
                                            let category = categories.filter(
                                                (category) => category.reference === e.target.value
                                            );

                                            if (category.length > 0) {
                                                setPut({
                                                    ...put,
                                                    primary_category: category[0].id,
                                                });
                                            }
                                        }}
                                    />
                                    <datalist id="categories">
                                        {categories.map((category, i) => (
                                            <option value={category.reference} key={i} />
                                        ))}
                                    </datalist>
                                </td>
                            </tr>
                            <tr>
                                {/*Income Accounts*/}
                                <IncomeAcc
                                    product={product}
                                    populateAccounts={populateAccounts}
                                    put={put}
                                    setPut={setPut}
                                />
                            </tr>
                            <tr>
                                <td colSpan="6">
                                    <b>
                                        Toggle Purchased from Vendor to 'true' to enable Expense Account and Purchase
                                        Description
                                    </b>
                                </td>
                            </tr>
                            <tr>
                                {/*Purchased From Vendor*/}
                                <td colSpan="3">
                                    <label htmlFor="use_qb_description">Purchased from Vendor: </label>
                                    <input
                                        type="checkbox"
                                        id="qb_vendor_purchase"
                                        defaultChecked={product.qb_vendor_purchase}
                                        onChange={(e) => {
                                            setVendorPurchase(e.target.checked);
                                            setPut({ ...put, qb_vendor_purchase: e.target.checked });
                                        }}></input>
                                </td>

                                {/*Expense Account*/}
                                <td>Expense Account</td>
                                <ExpenseAcc
                                    vendorPurchase={vendorPurchase}
                                    put={put}
                                    setPut={setPut}
                                    product={product}
                                    populateAccounts={populateAccounts}
                                />
                            </tr>
                            <tr>
                                {/*Purchase Description*/}
                                <td>Purchase Description:</td>
                                {vendorPurchase ? (
                                    <td colSpan="5">
                                        <input
                                            className="inputWidth"
                                            id="qb_purchase_description"
                                            defaultValue={product.qb_purchase_description}
                                            onChange={(e) =>
                                                setPut({ ...put, qb_purchase_description: e.target.value })
                                            }></input>
                                    </td>
                                ) : (
                                    <td colSpan="5">
                                        <input
                                            className="inputWidth"
                                            id="qb_purchase_description"
                                            value=""
                                            disabled={true}></input>
                                    </td>
                                )}
                            </tr>
                            <tr className='w-100'>
                                {/*Product Image*/}
                                <div className='felx flex-row w-75vw'>
                                    <td className='w-100'>Product Image:</td>
                                    {/* <div className='flex justify-center align-center'> */}
                                        {/* <div className="darken-background b2-green border-radius-xs box-shadow text-green text-bold margin-t-lg pad-xs flex align-center width-40 pointer">
                                            <input type="file" id={`file-input-image`} onChange={async (e) => {
                                                    await handleUpload(e)
                                                }} accept=".png,.jpg,.jpeg ,.pdf" hidden></input>
                                            <label htmlFor={`file-input-image`} className="width-100 flex align-center justify-even pointer">
                                                Upload
                                                <img src={uploadIcon} alt="upload icon" />
                                            </label>
                                        </div> */}

                                </div>

                                <div className="darken-background border-radius-xs box-shadow text-green text-bold margin-t-lg pad-xs flex align-center width-40 pointer">
                                            <input type="file" id={`file-input-image`} onChange={async (e) => {
                                                    await handleUpload(e)
                                                }} accept=".png,.jpg,.jpeg ,.pdf" hidden></input>
                                            <label htmlFor={`file-input-image`} className="width-100 flex align-center justify-even pointer">
                                                Upload
                                                <img src={uploadIcon} alt="upload icon" />
                                            </label>
                                        </div>
                                

                                    <div className='w-75vw'>
                                        <GenericTable columns={columns} data={imageLinks} actions={actions} savedCells={savedCells} style={{ noScroll: true }}/>
                                    </div>
                                    {/* {imageLinks.length > 0 ? (imageLinks.map((image, index) => (
                                        <></>
                                        // <>
                                        //     <p>{image.alt}</p>
                                        //     <p>{image.thumbnail}</p>
                                        //     <p>{image.image}</p>
                                        // </>
                                        // <button
                                        //     key={`${product.product_name}-view-image-${index}`}
                                        //     onClick={() => handleViewImage(image.url?.Location)} // Pass the image URL to the handler
                                        //     className="flex align-center justify-center bg-white border-radius-xs border-green text-green pad-xs pointer margin-l-sm"
                                        // >
                                        //     <img
                                        //         src={image.url?.Location} // Use the image URL from the parsed object
                                        //         className='thumbnail pad-r-xs'
                                        //         alt={image.alt || 'thumbnail'} // Use alt text or default to "thumbnail"
                                        //     />
                                        //     View
                                        // </button>
                                        // ))
                                    ))) : (
                                        <p>No images available</p>
                                    )} */}
                                    <div className={`${showImage.status ? "modal b-none border-radius-xs" : "hide"}`}>
                                        <button onClick={handleModal} className='bg-white b-none close-button'>
                                            <img src={xIcon} alt="close button" />
                                        </button>
                                        {showImage.type === "image" ?
                                            <img src={showImage.url}
                                                alt="placeholder"
                                                className="resize-image" />
                                        :
                                            (showImage.url?.length > 0)
                                                ? <iframe
                                                    src={`https://docs.google.com/gview?url=${showImage.url}&embedded=true`}
                                                    className="width-100 height-100"
                                                    title="file">
                                                </iframe>
                                                : null
                                        }
                                    </div>
                                    <div id="page-mask"
                                        className={`${showImage.status ? "" : "hidden"}`}>
                                    </div>
                                {/* </div> */}
                            </tr>
                            <tr className="headBorder"></tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {modal === 'add' ? (
                <Btn
                    btnClass="updateProduct pad-tb-md min-width-150px justify-center box-shadow bg-white text-green text-bold b2-green darken-background"
                    title="Create Product"
                    onClick={(e) => {
                        addProduct(e);
                    }}
                />
            ) : (
                <Btn
                    btnClass="updateProduct blue-button b2-blue text-white bg-blue text-bold box-shadow pad-tb-md"
                    title="Update Product"
                    onClick={(e) => {
                        updateProduct(e);
                    }}
                />
            )}
        </div>
    );
};

export default Modal;
